import ModalForm from 'js/base_v2/modal-form';

/**
 * Resend Email Verification Link Form.
 *
 * @class
 * @extends ModalForm
 *
 * @param {object} [options]
 */
function ResendEmailVerificationLinkForm(options) {
  ModalForm.call(this, options, true);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      tmplEl: '#resend-email-verification-link-form',
      tmplParams: {
        title: this.translator.getTitle('resend_email_verification_link'),
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.afterSubmit = function() {
    this.notifier.notifyInfo(this.translator.get(
      'success_email_verification_link_sent',
    ));

    this.formEl.find('.hideablePart').hide();

    return false;
  };

  /**
   * @inheritDoc
   */
  this.getConstraints = function() {
    return {
      email: {
        required: {
          message: this.translator.get('error_empty_email'),
        },
      },
    };
  };

  // Initialize
  this.init();
}

export default ResendEmailVerificationLinkForm;
