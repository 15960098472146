/* eslint-disable max-len */
import CreateAccountForm from 'js/auth/forms/create-account-form';
import ImpersonateForm from 'js/auth/forms/impersonate-form';
import LoginFormGroup from 'js/auth/forms/login-form-group';
import RecoverPasswordForm from 'js/auth/forms/recover-password-form';
import ResendEmailVerificationLinkForm from 'js/auth/forms/resend-email-verification-link-form';
import BaseView from 'js/base_v2/view';
import Ajax from 'js/components/ajax';
import Renderer from 'js/components/renderer';
import CollapsableTopBlock from 'js/components_v2/collapsable-top-block';
import MessagesPanelView from 'js/messages_v2/views/panel-view';
import PartSearchTypeaheadField from 'js/part/fields/search-typeahead-field';
import { notifier } from 'js/components_v2/default-notifier';
/* eslint-enable max-len */

/**
 * Base Dashboard View.
 *
 * @class
 * @abstract
 * @extends BaseView
 *
 * @param {object} [options]
 */
function BaseDashboardView(options) {
  BaseView.call(this, options);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {boolean}
   */
  this.collapseOnTableScroll = true;

  /**
   * @prop {LoginFormGroup}
   */
  this.loginFormGroup = null;

  /**
   * @prop {CreateAccountForm}
   */
  this.createAccountForm = null;

  /**
   * @prop {RecoverPasswordForm}
   */
  this.recoverPasswordForm = null;

  /**
   * @prop {ResendEmailVerificationLinkForm}
   */
  this.resendEmailVerificationLinkForm = null;

  /**
   * @prop {PartSearchTypeaheadField}
   */
  this.partSearchField = null;

  /**
   * @prop {MessagesPanelView}
   */
  this.messagesPanelView = null;

  /**
   * @prop {ImpersonateForm}
   */
  this.impersonateForm = null;

  /**
   * @prop {?CollapsableTopBlock}
   */
  this.collapsableTopBlock = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      collapsableTopBlockEnabled: false,
      listName: null,
      maxTableScrollTop: 250,
      loginFormGroup: {},
      createAccountForm: {},
      recoverPasswordForm: {},
      resendEmailVerificationLinkForm: {},
      partSearchField: {
        onRecordSelected: (event, record) => {
          this.goToPartDetailsPage(record);
        },
      },
      messagesPanelCt: $('div.relative:last'),
      messagesPanelView: {
        project: null,
      },
      impersonateForm: {},
      collapsableTopBlock: {
        onCollapseTopBlockStart: () => {
          this.onTopBlockEventStart();
        },
        onCollapseTopBlockEnd: () => {
          this.onTopBlockEventEnd();
        },
        onExpandTopBlockStart: () => {
          this.onTopBlockEventStart();
        },
        onExpandTopBlockEnd: () => {
          this.onTopBlockEventEnd();
        },
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    if (_.isString(this.options.listName)) {
      this.processListOptions();
    }

    return this;
  };

  /**
   * Process list options.
   *
   * @return {BaseDashboardView}
   */
  this.processListOptions = function() {
    return this.extendOptions(Object.createFromKey(
      this.options.listName,
      {
        onTableScroll: (scrollBody) => {
          this.onTableScroll(scrollBody);
        },
      },
    ));
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    parent.create.call(this);

    _.each(window.ALERT_MESSAGES, (messages, messageType) => {
      _.each(messages, (message) => {
        notifier.notify(message, messageType, { clear: false });
      });
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    $(document).on('click', '.signIn', (event) => {
      this.onLoginBtnClick(event);
    });

    $(document).on('click', '.createAccount', (event) => {
      this.onCreateAccountBtnClick(event);
    });

    $(document).on('click', '.recoverPassword', (event) => {
      this.onRecoverPasswordBtnClick(event);
    });

    $(document).on('click', '.resendEmailVerificationLink', (event) => {
      this.onResendEmailVerificationLinkBtnClick(event);
    });

    $(document).on('submit', '.partSearchForm', (event) => {
      this.onPartSearchFormSubmit(event);
    });

    $(document).on('click', '.impersonate', (event) => {
      this.onImpersonateBtnClick(event);
    });

    $(document).on('click', '.showMessages', function(event) {
      self.onShowMessagesBtnClick($(this), event);
    });

    $(document).on('click', '.switchUserVersion', (event) => {
      this.onSwitchUserVersionBtnClick(event);
    });

    return this;
  };

  /**
   * Table scroll event handler.
   *
   * @param {DOMElement} scrollBody
   */
  this.onTableScroll = function(scrollBody) {
    if (!this.options.collapsableTopBlockEnabled) {
      return;
    }

    const top = scrollBody.scrollTop();

    if (top > this.options.maxTableScrollTop &&
      this.collapseOnTableScroll
    ) {
      this.collapsableTopBlock.collapseTopBlock();
    }
  };

  /**
   * Create account button click event handler.
   *
   * @param {Event} event
   */
  this.onCreateAccountBtnClick = function(event) {
    event.preventDefault();

    this.createCreateAccountForm();
  };

  /**
   * Recover password button click event handler.
   *
   * @param {Event} event
   */
  this.onRecoverPasswordBtnClick = function(event) {
    event.preventDefault();

    this.createRecoverPasswordForm();
  };

  /**
   * Resend email verification link button click event handler.
   *
   * @param {Event} event
   */
  this.onResendEmailVerificationLinkBtnClick = function(event) {
    event.preventDefault();

    this.createResendEmailVerificationLinkForm();
  };

  /**
   * Login button click event handler.
   *
   * @param {Event} event
   */
  this.onLoginBtnClick = function(event) {
    event.preventDefault();

    this.createLoginFormGroup();
  };

  /**
   * Part search form submit event handler.
   *
   * @param {Event} event
   */
  this.onPartSearchFormSubmit = function(event) {
    event.preventDefault();

    this.goToPartSearchPage();
  };

  /**
   * Impersonate button click event handler.
   *
   * @param {Event} event
   */
  this.onImpersonateBtnClick = function(event) {
    event.preventDefault();

    this.createImpersonateForm();
  };

  /**
   * Switch user version view button click event handler.
   *
   * @param {Event} event
   */
  this.onSwitchUserVersionBtnClick = function(event) {
    event.preventDefault();

    this.switchUserVersion();
  };

  /**
   * Show messages button click event handler.
   *
   * @param {DOMElement} button
   * @param {Event}      event
   */
  this.onShowMessagesBtnClick = function(button, event) {
    event.preventDefault();

    button.toggleClass('active');

    this.messagesPanelView.toggle();
  };

  /**
   * Top block start event handler.
   */
  this.onTopBlockEventStart = function() {
    this.collapseOnTableScroll = false;

    this.getList().adjustTableHeight();
  };

  /**
   * Top block end event handler.
   */
  this.onTopBlockEventEnd = function() {
    this.getList().adjustTableHeight();

    setTimeout(() => {
      this.collapseOnTableScroll = true;
    }, 100);
  };

  /**
   * @inheritDoc
   */
  this.createComponents = function() {
    parent.createComponents.call(this);

    this
      .createPartSearchField()
      .createMessagesPanelView();

    if (this.options.collapsableTopBlockEnabled) {
      this.createCollapsableTopBlock();
    }

    return this;
  };

  /**
   * Create login form group.
   *
   * @param  {object}            [formGroupOptions]
   * @return {BaseDashboardView}
   */
  this.createLoginFormGroup = function(formGroupOptions = {}) {
    this.loginFormGroup = new LoginFormGroup(
      {},
      this.options.loginFormGroup.extend(formGroupOptions),
    ).create();

    return this;
  };

  /**
   * Create create account form.
   *
   * @return {BaseDashboardView}
   */
  this.createCreateAccountForm = function() {
    this.createAccountForm = new CreateAccountForm(
      this.options.createAccountForm,
    ).create();

    return this;
  };

  /**
   * Create recover password form.
   *
   * @return {BaseDashboardView}
   */
  this.createRecoverPasswordForm = function() {
    this.recoverPasswordForm = new RecoverPasswordForm(
      this.options.recoverPasswordForm,
    ).create();

    return this;
  };

  /**
   * Create resend email verification link form.
   *
   * @return {BaseDashboardView}
   */
  this.createResendEmailVerificationLinkForm = function() {
    this.resendEmailVerificationLinkForm = new ResendEmailVerificationLinkForm(
      this.options.resendEmailVerificationLinkForm,
    ).create();

    return this;
  };

  /**
   * Create part search field.
   *
   * @return {BaseDashboardView}
   */
  this.createPartSearchField = function() {
    this.partSearchField = new PartSearchTypeaheadField(
      $('.partSearchFieldCt'),
      $('.partSearchFieldCt .partSearchInput'),
      this.options.partSearchField,
    ).create();

    return this;
  };

  /**
   * Create messages panel view.
   *
   * @return {BaseDashboardView}
   */
  this.createMessagesPanelView = function() {
    if (this.options.messagesPanelCt) {
      this.messagesPanelView = new MessagesPanelView(
        this.options.messagesPanelCt,
        this.options.messagesPanelView,
      ).create();
    }

    return this;
  };

  /**
   * Create impersonate form.
   *
   * @return {BaseDashboardView}
   */
  this.createImpersonateForm = function() {
    this.impersonateForm = new ImpersonateForm(
      this.options.impersonateForm,
    ).create();

    return this;
  };

  /**
   * Save corporate user's version.
   *
   * @return {BaseDashboardView}
   */
  this.switchUserVersion = function() {
    notifier.notifyLoading(
      this.translator.get('change_user_version_in_progress'),
    );

    /* eslint-disable-next-line max-len */
    const alternateVersionEnabled = window.CURRENT_USER.alternate_version_enabled ?
      0 :
      1;

    Ajax.post(
      '/corporate/user/save',
      {
        corporate_user: {
          id: window.CURRENT_USER.corporate_user_id,
          alternate_version_enabled: alternateVersionEnabled,
        },
      },
      () => window.location.reload(),
      (response, error) => {
        /* eslint-disable-next-line max-len */
        const message = `${this.translator.get('change_user_version_error_prefix')}: ${error}`;

        notifier.notifyError(message);
      },
    );

    return this;
  };

  /**
   * Create collapsable top block.
   *
   * @return {BaseDashboardView}
   */
  this.createCollapsableTopBlock = function() {
    this.collapsableTopBlock = new CollapsableTopBlock(
      $('.collapsableTopBlock'),
      this.options.collapsableTopBlock,
    ).create();

    return this;
  };

  /**
   * Go to part search page.
   *
   * @return {BaseDashboardView}
   */
  this.goToPartSearchPage = function() {
    const query = this.partSearchField.getValue();
    window.location.href = `/part_v2/search?q=${query}`;

    return this;
  };

  /**
   * Go to part details page.
   *
   * @param  {object}            part
   * @return {BaseDashboardView}
   */
  this.goToPartDetailsPage = function(part) {
    window.location.href = Renderer.PartUrl(part);

    return this;
  };

  /**
   * Get list.
   *
   * @return {?BaseList}
   */
  this.getList = function() {
    if (_.isString(this.options.listName)) {
      return this[this.options.listName];
    }

    return null;
  };
}

export default BaseDashboardView;
